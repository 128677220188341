@import "../../global.scss";

.about {
    @include section;
    background-color: $whiteColor;

    .pictureTextContainer {
        display: flex;

        @include mobile {
            flex-direction: column;
          }      

        .aboutPicture {
            margin: 0px 20px;
            object-fit: contain;
            border-radius: 20px;

            @include mobile {
                margin: 20px 0px;
              }     
        }
    }
}