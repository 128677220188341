@import "../../global.scss";

.introduction {
    @include section;
    background: linear-gradient(90deg, $mainColor, $secondaryColor);
    height: 80vh;
    display: flex;
    align-items: center;
    color: $whiteColor;

    @include mobile {
        flex-direction: column;
        margin: 0;
    }

    .description {
        h1 {
            font-family: $headerFont;
            font-weight: 300;
            font-size: 90px;
            margin: 10px 0;
            // background: -webkit-linear-gradient($whiteColor, $thirdColor);
            // -webkit-background-clip: text;
            // background-clip: text;
            // -webkit-text-fill-color: transparent;
        }
        h2 {
            font-size: 35px;
        }
        h3 {
            font-size: 30px;
            background: -webkit-linear-gradient($peachColor, $pinkColor);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

}