@import "../../global.scss";

.navbar {
    width: 100%;
    height: 70px;
    background: linear-gradient(45deg, $mainColor, $secondaryColor);
    color: $whiteColor;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    font-family: $headerFont;

    @include mobile {
        flex-direction: column;
        min-height: 70px;
        height: auto;
    }

    .logoAndIconContainer {
        padding: 10px 30px;
        display: flex;
        align-items: center;
        padding-left: 15vw;

        .logoContainer {
            .logo {
                font-size: 40px;
                font-weight: 500;
                color: inherit;
                text-decoration: none;
            }
        }

        .hamburgerContainer {
            .hamburgerIcon {
                display: none;
                position: absolute;
                right: 10px;
                top: 10px;
                font-size: 40px;
                cursor: pointer;

                @include mobile {
                    display: block;
                }
            }
        }
    }
    .navigation {

        ul {
            display: flex;
            list-style: none;
            height: 100%;
            align-items: center;
            padding-right: 15vw;
            margin: 0;

            @include mobile {
                flex-direction: column;
                align-items: start;
            }

            .navigationItem {
                padding-right: 20px;
                font-size: 25px;

                @include mobile {
                    width: 100%;
                    padding: 15px 30px;
                    border-top: 1px solid rgb(194, 194, 194);
                }
                a {
                    text-decoration: none;
                    color: white;
                }
            }
        }
    }
}