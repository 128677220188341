@import './global.scss';

@import url('https://fonts.googleapis.com/css?family=Bebas+Neue');
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Agbalumo');

.app {
  height: 100vh;
  font-family: $mainFont;

  .sections {
      width: 100%;
      height: calc(100vh - 70px);
      position: relative;
      top: 70px;
      scroll-behavior: smooth;
      scroll-snap-type: y mandatory;
      scrollbar-width: none; //for firefox
      &::-webkit-scrollbar {
          display: none;
      }
  }

  .sectionTitle {
    padding-left: 10vw;
    align-content: center;
    padding-bottom: 20px;

    @include mobile {
        padding-left: 0px;
    }
  }

}

