@import "../../global.scss";

.contact {
    @include section;
    background-color: $mainColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;

    h1 {
        color: $whiteColor;
        padding: 0 !important;
    }

    p {
        font-size: 16px;
    }

    .contactButtons {
        padding: 30px;
        a {
            text-decoration: none;
            padding: 15px;

            .icon {
                color: $whiteColor;
                font-size: 48px;
            }
        }
    }
}