@import "../../global.scss";

.portfolio {
    @include section;
    padding: 100px 5vw;
    background: linear-gradient(80deg, $peachColor, $pinkColor);
    // color: $whiteColor;

    .sectionTitle {
        color: $mainColor;

    }

    .portfolio-wrapper {
        display: flex;
        flex-direction: column;

        .project {
          display: flex;
          flex-direction: row;
          margin-bottom: 140px;
          
          &:nth-child(even) { // Apply row-reverse for even projects
            flex-direction: row-reverse;
          }

          @include mobile {
            margin-bottom: 40px;
            flex-direction: column !important; // important is necessary otherwise even rows will still be row-reverse
          }
     
        
          .projectDetails {
              max-width: 45vw;
              
              @include mobile {
                max-width: 80vw;
              }
              
              .projectText {
                padding: 25px;
                background-color: $whiteColor;
                border-radius: 20px;
                margin-bottom: 30px;
          
                @include mobile {
                  margin: 0px 10px;
                  padding: 20px;
                  margin-bottom: 20px;
                }
              
              
                .client {
                  font-size: 2.5rem;
                  padding-left: 5%;
                  margin-bottom: 10px;
                }
                
                .role, .tasks, .goals, .description {
                  margin-bottom: 10px;
                }
            
                .detail {
                  font-weight: bold;
                }
              }
              
            .horizontalMedia {
              height: 400px;
              overflow: hidden;

              @include mobile {
                height: 200px;
              }

            }

          }
        
          .projectMedia {
            display: flex; 
            flex-wrap: wrap;
            
            @include mobile {
              flex-direction: column;
            }
        
            .verticalMedia {
              max-width: 15vw;
              margin: 10px;
        
              @include mobile {
                max-width: 90vw;
              }
            }
        
            // .tiktokVideo {
            //   min-width: 325px;
        
            //   @include mobile {
            //     width: 325px;
            //     margin: auto;
            //   }
            // }
        
          }
        }        

    }

}